import React, { Fragment, useContext } from 'react';
import ExistingVisaResult from './ExistingVisaResult';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { CountryContext } from '../context/CountryContext';
import { AssessmentContext } from '../context/AssessmentContext';
import EXISTING_VISA_STEP_INDEX from '../consts/EXISTING_VISA_STEP_INDEX';
// import ExistingVisaIntroduction from './ExistingVisaIntroduction';
import ExistingVisaNodes from './ExistingVisaNodes';
import ExistingVisaSelection from './ExistingVisaSelection';
import ErrorOutlineOutlined from '@material-ui/icons/ErrorOutlineOutlined';
import config from '../config';

const ExistingVisa = ({ isModalOpen, setIsModalOpen, hasExistingVisa, setHasExistingVisa }) => {
  // Context variables
  const [assessmentState, assessmentDispatch] = useContext(AssessmentContext);
  const [countryState, countryDispatch] = useContext(CountryContext);


  const resetAssessment = () => {
    countryDispatch({ type: 'RESET_COUNTRIES' });
    assessmentDispatch({ type: 'RESET_ASSESSMENT' });
  };

  const closeExistingVisaModal = () => {
    if (assessmentState.currentExistingVisaIndex === EXISTING_VISA_STEP_INDEX.result) {
      if (assessmentState.existingVisaResult && assessmentState.existingVisaResult.isContinuingToAssessment) {
        assessmentDispatch({
          type: 'UPDATE_INDEX',
          currentIndex: 1,
          currentRequirementIndex: 0,
        });
      } else if (assessmentState.existingVisaResult && !assessmentState.existingVisaResult.isContinuingToAssessment) {
        resetAssessment();
      }
    }

    assessmentDispatch({ type: 'RESET_EXISTING_VISA' });
    toggleExistingVisaModal();
  };

  const toggleExistingVisaModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="gw__question__existingvisa__wrap">
      <div className="gw__assessment-container">
        <p className="gw__assessment-question">Does the candidate hold an existing visa for the destination country?</p>  

        <div className="d-flex mt-5 mb-5">
          <RadioGroup
            aria-label="select-if-candidate-hase-current-visa"
            name="current-visa-radio"
            value={hasExistingVisa}
            onChange={event => setHasExistingVisa(event.target.value === 'true')}
            row
          >
            <FormControlLabel
              value={true}
              control={<Radio color="primary" />}
              label="YES"
              labelPlacement="end"
              className="pr-5"
            />
            <FormControlLabel
              value={false}
              control={<Radio color="primary" />}
              label="NO"
              labelPlacement="end"
              className="pl-3" 
            />
          </RadioGroup>
        </div>
      </div>

      <Modal
        className="gw__existing-visa-modal"
        centered
        isOpen={isModalOpen}
        toggle={toggleExistingVisaModal}
      >
        <ModalHeader className="d-flex justify-content-end">
          <i className="icon-cross_special" onClick={closeExistingVisaModal} />
        </ModalHeader>
        <ModalBody>
          <div className="gw__existing-visa-container">
            <div className="d-flex justify-content-center align-items-center h-100">
              {
                countryState.selectedDestinationCountryExistingVisas.length <= 0
                ? <div className="row">
                    <div className="col-12 d-flex justify-content-between align-items-center mb-3">
                      <span className="gw__existing-visa-title">Further assessment needed</span>
                      <ErrorOutlineOutlined className="gw__warning-icon" />
                    </div>
              
                    <div className="col-12">
                      <p className="gw__existing-visa-subtext">
                        The candidate's current visa needs a detailed review by an immigration specialist to determine
                        work eligibility. <br />To request a detailed assessment please <a className="gw__help-and-support-link" href={config.resultsFullAssessmentRequiredMailTo}>contact us</a>.
                      </p>
                    </div>
              
                    <div className="col-12 d-flex justify-content-end mt-5">
                      <button className="btn btn-primary" onClick={toggleExistingVisaModal}>CLOSE</button>
                    </div>
                  </div>
                : <Fragment>
                    {/* {
                      assessmentState.currentIndex === 0 && assessmentState.currentExistingVisaIndex === EXISTING_VISA_STEP_INDEX.introduction &&
                      <ExistingVisaIntroduction country={countryState.selectedDestinationCountry} />
                    } */}
                    {
                      assessmentState.currentIndex === 0 && assessmentState.currentExistingVisaIndex === EXISTING_VISA_STEP_INDEX.selectVisa &&
                      <ExistingVisaSelection />
                    }
                    {
                      assessmentState.currentIndex === 0 && assessmentState.currentExistingVisaIndex === EXISTING_VISA_STEP_INDEX.nodes &&
                      <ExistingVisaNodes />
                    }
                    {
                      assessmentState.currentIndex === 0 && assessmentState.currentExistingVisaIndex === EXISTING_VISA_STEP_INDEX.result &&
                      <ExistingVisaResult />
                    }
                  </Fragment>
              }
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ExistingVisa;
