import React from 'react';
import IconCheckmark from './IconCheckmark';
import IconCross from './IconCross';
import ResultsWorkPermitTable from './ResultsWorkPermitTable';
import MuiExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import MuiExpansionPanel from "@material-ui/core/ExpansionPanel";
import { ExpansionPanelDetails, withStyles } from '@material-ui/core';

const ExpansionPanel = withStyles({
  root: {
    border: "none",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: "none",
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
  root: {
    backgroundColor: "white",
    borderBottom: "1px solid #d1d1d1",
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiExpansionPanelSummary);

const ResultsEligibility = ({ workPermits, ineligibleWorkPermits }) => {
  return (
    <div className="row gw__results-row mt-md-5">
      {
        workPermits.length > 0 && !workPermits[0].isManuallyAssessed
        ? <div className="col-12">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="col-12 col-md-9 p-0">
                <div className="d-flex align-items-center">
                  <h1 className="gw__results-title">Assessment result: <span className="text-primary">Eligible</span></h1>
                  <div className="gw__results-success">
                    <IconCheckmark />
                  </div>
                </div>
                <p className="gw__results-subtext">
                  As based on general eligibility requirements, this assessment is indicative only.
                  If the candidate proceeds with the work permit, they will still need a full consultation.
                </p>
              </div>
              <button className="btn btn-primary mr-auto mb-4" onClick={() => window.location.replace('/#/assessment')}>RETURN TO ASSESSMENT</button>
            </div>
          </div>
        : <div className="col-12">
            <div className="d-flex align-items-start flex-column flex-md-row">
              <div className="col-12 col-md-9 p-0">
                <div className="d-flex align-items-center">
                  <h1 className="gw__results-title">Assessment result: <span className="text-danger">Ineligible</span></h1>
                  <div className="gw__results-failure">
                    <IconCross />
                  </div>
                </div>
                <p className="gw__results-subtext">
                  Please keep in mind that this assessment is indicative only. The candidate can still go through a full
                  assessment to confirm the result and make sure information not entered into the tool is considered.
                </p>
                <hr/>
              </div>
              <button className="btn btn-primary mr-auto mb-4" onClick={() => window.location.replace('/#/assessment')}>RETURN TO ASSESSMENT</button>
            </div>
            <div className="row no-gutters">
              <p className="gw__results-permit-heading">
                <i className="icon-briefcase_special gw__results-icon" />
                {
                  ineligibleWorkPermits.length > 1 ? 'Closest Permit' : 'Closest Permit'
                }
              </p>
            </div>
            <div className="row no-gutters">
              {
                ineligibleWorkPermits.map((iewp, index) => (
                  <div key={iewp.name} className="col-12 col-md-9 p-0">
                    <ExpansionPanel defaultExpanded={index === 0}>
                      <ExpansionPanelSummary>
                        <div className="d-flex justify-content-between align-items-center w-100">
                          <h1 className="gw__help-and-support-section-title font-weight-extra-bold">{iewp.name}</h1>
                          <i className="icon-simple_arrow_down_special gw__help-and-support-select-icon" />
                        </div>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <div className="w-100">
                          <p>{iewp.workPermit.workPermitRevisions[0].description}</p>
                          <div className="py-2">
                          <ResultsWorkPermitTable ineligiblePermit={iewp} />
                          </div>
                        </div>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                  </div>
                ))
              }
            </div>
          </div>
      }
    </div>
  );
};

export default ResultsEligibility;
